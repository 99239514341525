import React from "react";
// import { ReactComponent as Cherry } from "../Components/SvgImages/Cherry.svg";
import { ReactComponent as Android } from "../Components/SvgImage/Android.svg";
import { ReactComponent as IOS } from "../Components/SvgImage/IOS.svg";
import "../Pages/Dog.css";

const dog = () => {
  const handleAndroidClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.onelovepc&hl=en_IN', '_blank');
  };

  // const handleIOSClick = () => {
  //   window.open('https://apps.apple.com/app/idYOUR_APP_ID', '_blank');
  // };
  return (
    <div>
      <div className="container">
        <div>
          <center>
            <h1 className="dog-heading">Simplify Pet Care Coordination</h1>

            <p className="dog-para">
              Book grooming, vet appointments, and shop for all your pet needs
              seamlessly in one app.
            </p>
          </center>
        </div>
        <center>
          <div className="btn-cont">
            <button onClick={handleAndroidClick} className="store-button">
              <Android className="android-image" />
            </button>
            <button className="store-button">
              <IOS className="android-image" />
            </button>
          </div>
        </center>
        {/* <div>
          <img
            src="https://i.ibb.co/Ssqk7rB/dog-1.png"
            className="dog"
            alt="puppy"
          />
        </div> */}
      </div>
    </div>
    // <div>
    //   <div className="container">
    //     <div>
    //       <div>
    //         <center>
    //           <h1 className="dog-heading">Simplify Pet Care Coordination</h1>

    //           <p className="dog-para">
    //             Streamline Pet Care Communication and Tracking
    //           </p>
    //         </center>
    //       </div>
    //       <div className="download-container">
    //         {/* <button className="download-">
    //           <Android className="android-image" />
    //           Download on Android
    //         </button> */}

    //         <Android className="android-image btn-cont" />

    //         {/* <button className="download-">Download on IOS</button> */}
    //         <IOS className="android-image" />
    //       </div>
    //     </div>
    //     <div>
    //       <img
    //         src="https://i.ibb.co/Ssqk7rB/dog-1.png"
    //         className="dog"
    //         alt="puppy"
    //       />
    //     </div>
    //   </div>
    // </div>
  );
};

export default dog;
